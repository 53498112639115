@import '../node_modules/leaflet/dist/leaflet.css';
@import './modal.css';

body {
	margin: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - 4rem);
	font-family: Arial;
}

a {
	text-decoration: none;
	color: #888;
}

a:hover {
	color: #000;
}

h2 {
	margin-bottom: 0rem;
}

p {
	margin-top: 0.25rem;
}

noscript {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: white;
	display: flex;
	align-items: center;
	padding: 1em;
	box-sizing: border-box;
}

noscript .message {
	max-width: 35rem;
	margin: 0 auto;
	text-align: center;
}

header {
	margin-top: 3rem;
	margin-bottom: 5rem;
	width: 100%;
	max-width: 35rem;
}

input {
	width: 100%;
	height: auto;
	padding-left: 0;
	padding-bottom: .75rem;
	border-width: 0 0 .0625rem;
	border-style: solid;
	border-color: #ddd;
	border-radius: 0;
	outline: none;
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

@media only screen and (max-width: 39rem) {
	header {
		margin-top: 0rem;
		margin-bottom: 2rem;
	}
}

.actions > .action {
	color: #888;
	cursor: pointer;
	font-size: 0.875rem;
	margin-right: 1rem;
}

.actions > .action:hover {
	color: black;
}

.actions > .wat {
	float: right;
	margin-right: 0rem;
}

.actions > .error {
	color: red;
	visbility: hidden;
}

#map {
	width: 100%;
	flex: 1;
	min-height: 25rem;
}

.leaflet-popup-content-wrapper {
	border-radius: 0rem;
}

.leaflet-popup-content {
	margin: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loading {
	display: none;
	animation: spin .7s cubic-bezier(.22,.61,.36,1) infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	20% {
		transform: rotate(110deg);
	}
	100% {
		transform: rotate(1turn);
	}
}
