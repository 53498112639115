.modal {
	display: block;
	position: fixed;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	z-index: -1;
	opacity: 0;
	transition: opacity 0.2s, z-index 0s 0.2s;
	text-align: center;
	overflow: hidden;
	overflow-y: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

.modal > * {
	display: inline-block;
	white-space: normal;
	vertical-align: middle;
	text-align: left;
}

.modal:before {
	display: inline-block;
	overflow: hidden;
	width: 0;
	height: 100%;
	vertical-align: middle;
	content: "";
}

.modal.visible {
	z-index: 9999;
	opacity: 1;
	transition: opacity 0.2s;
}

.modal .inner {
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	max-width: calc(100vw - 4rem);
	max-height: calc(100vh - 4rem);
	overflow-x: hidden;
	overflow-y: auto;
	background: #fff;
	z-index: -1;
	opacity: 0;
	transform: scale(0);
	transition: opacity 0.2s, transform 0.2s, z-index 0s 0.2s;
	padding: 2rem;
}

.modal.visible .inner {
	z-index: 100;
	opacity: 1;
	transform: scale(1);
}

.modal .inner > :nth-child(2) {
	margin-top: 0rem;
}

.modal .inner > :last-child {
	margin-bottom: 0rem;
}

.modal .close {
	color: #888;
	position: absolute;
	z-index: 2;
	right: 0;
	top: 0;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	cursor: pointer;
	text-align: center;
}

.modal .close:hover {
	color: #000;
}
